import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { useHistory } from "react-router-dom";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  Menu,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setVisibility(false);
  };

  const navigateToSupport = () => {
    history.push("/support"); // Redirect to the support page
    setVisibility(false);
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="horizontal-logo.svg" width="300px" height="100px" />
          </LogoContainer>
          <NotHidden>
            <CustomNavLinkSmall style={{ width: "180px" }} onClick={() => scrollTo("contact")}>
              <Span>
                <Button>{t("Contact")}</Button>
              </Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall style={{ width: "180px" }} onClick={navigateToSupport}>
              <Span>
                <Button>{t("Support")}</Button>
              </Span>
            </CustomNavLinkSmall>
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          {/* Drawer Menu Links */}
          <Menu onClick={() => scrollTo("contact")}>{t("Contact")}</Menu>
          <Menu onClick={navigateToSupport}>{t("Support")}</Menu>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);

import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { colors, typography, spacing, transitions, shadows, borderRadius } from "../../styles/theme";

export const HeaderSection = styled("header")`
  padding: ${spacing[4]} ${spacing[2]};
  background-color: ${colors.background.light};
  box-shadow: ${shadows.sm};
  position: sticky;
  top: 0;
  z-index: 1000;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  transition: ${transitions.default};

  &:hover {
    opacity: 0.8;
  }
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: ${colors.primary[400]};
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: ${typography.fontSize['2xl']};
  font-weight: ${typography.fontWeight.semibold};
  text-align: center;
  color: ${colors.text.primary};
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: ${typography.fontSize.lg};
  color: ${colors.primary[400]};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin: ${spacing[2]} ${spacing[8]};
  position: relative;

  @media only screen and (max-width: 768px) {
    margin: ${spacing[5]} ${spacing[8]};
  }

  button {
    background: ${colors.primary[400]};
    color: ${colors.text.light};
    padding: ${spacing[2]} ${spacing[4]};
    border-radius: ${borderRadius.md};
    font-weight: ${typography.fontWeight.medium};
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${shadows.sm};
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
      );
      transform: translateX(-100%);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${colors.text.light};
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      background: ${colors.primary[500]};
      transform: translateY(-2px);
      box-shadow: ${shadows.lg};

      &::before {
        transform: translateX(100%);
      }

      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    &:active {
      transform: translateY(0);
      box-shadow: ${shadows.md};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${colors.primary[100]};
    }
  }
`;

export const Label = styled("span")`
  font-weight: ${typography.fontWeight.medium};
  color: ${colors.text.secondary};
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: ${typography.fontSize['2xl']};
  color: ${colors.primary[400]};
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: ${transitions.default};
  position: relative;

  button {
    &:hover,
    &:active,
    &:focus {
      color: ${colors.text.light};
    }
  }
`;
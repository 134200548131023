import { createGlobalStyle } from "styled-components";
import { colors, typography, spacing, transitions } from "./theme";

export const Styles = createGlobalStyle`
  @font-face {
    font-family: "Motiva Sans Light";
    src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Motiva Sans Bold";
    src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${typography.fontFamily.sans};
    line-height: ${typography.lineHeight.normal};
    color: ${colors.text.primary};
    background-color: ${colors.background.light};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${typography.fontFamily.heading};
    color: ${colors.primary[400]};
    line-height: ${typography.lineHeight.tight};
    margin-bottom: ${spacing[4]};
  }

  h1 {
    font-size: ${typography.fontSize['5xl']};
    font-weight: ${typography.fontWeight.bold};
  }

  h2 {
    font-size: ${typography.fontSize['4xl']};
    font-weight: ${typography.fontWeight.semibold};
  }

  h3 {
    font-size: ${typography.fontSize['3xl']};
    font-weight: ${typography.fontWeight.semibold};
  }

  p {
    color: ${colors.text.secondary};
    font-size: ${typography.fontSize.base};
    line-height: ${typography.lineHeight.relaxed};
    margin-bottom: ${spacing[4]};
  }

  a {
    color: ${colors.primary[400]};
    text-decoration: none;
    transition: ${transitions.default};

    &:hover {
      color: ${colors.accent.coral};
    }
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  input, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }

  .login-block-image svg {
    text-align: center;
  }
`;
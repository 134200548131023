import styled from "styled-components";
import { colors, typography, spacing, shadows, transitions, borderRadius } from "../../styles/theme";

export const Section = styled.div`
  padding: ${spacing[12]} ${spacing[5]};
  text-align: center;

  &.hero-section {
    background-color: ${colors.background.light};
    color: ${colors.text.primary};
  }

  &.features-section {
    background-color: ${colors.background.light};
  }

  &.cta-section {
    background-color: ${colors.background.dark};
    color: ${colors.text.light};
  }
`;

export const Title = styled.h1`
  font-size: ${typography.fontSize['4xl']};
  margin-bottom: ${spacing[5]};
  color: inherit;
  font-weight: ${typography.fontWeight.bold};
`;

export const Subtitle = styled.p`
  font-size: ${typography.fontSize.xl};
  margin-bottom: ${spacing[8]};
  color: inherit;
  opacity: 0.9;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacing[4]};
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DownloadButton = styled.a`
  padding: ${spacing[3]} ${spacing[5]};
  text-decoration: none;
  color: ${colors.text.light};
  border-radius: ${borderRadius.lg};
  background-color: ${colors.accent.orange};
  transition: ${transitions.default};
  font-weight: ${typography.fontWeight.medium};
  box-shadow: ${shadows.md};

  &:hover {
    background-color: ${colors.accent.coral};
    transform: translateY(-2px);
    box-shadow: ${shadows.lg};
  }
`;

export const Feature = styled.div`
  text-align: center;
  padding: ${spacing[6]};
  background-color: ${colors.background.light};
  border-radius: ${borderRadius.lg};
  box-shadow: ${shadows.sm};
  transition: ${transitions.default};

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${shadows.md};
  }
`;

export const FeatureIcon = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: ${spacing[3]};
`;

export const FeatureText = styled.p`
  font-size: ${typography.fontSize.base};
  color: ${colors.text.secondary};
  line-height: ${typography.lineHeight.relaxed};
`;

export const QRCodeSection = styled.div`
  margin-top: ${spacing[12]};
  padding: ${spacing[8]} 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: ${borderRadius.xl};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing[6]};
  background: ${colors.background.light};
  border-radius: ${borderRadius.lg};
  box-shadow: ${shadows.md};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${shadows.lg};
  }
`;

export const QRCodeTitle = styled.h3`
  font-size: ${typography.fontSize.xl};
  font-weight: ${typography.fontWeight.semibold};
  color: ${colors.text.primary};
  margin-bottom: ${spacing[4]};
`;

export const QRCodeImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: ${spacing[4]};
  border-radius: ${borderRadius.md};
  box-shadow: ${shadows.sm};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: scale(1.05);
  }
`;

export const QRCodeText = styled.p`
  font-size: ${typography.fontSize.base};
  color: ${colors.text.secondary};
  line-height: ${typography.lineHeight.relaxed};
  margin: 0;
`;

import { createGlobalStyle } from "styled-components";

// Color Palette
export const colors = {
  primary: {
    50: '#E6F3F7',
    100: '#CCE7EF',
    200: '#99CFDF',
    300: '#66B7CF',
    400: '#297EA3', // Main brand color
    500: '#1F5F7A',
    600: '#164052',
    700: '#0C2029',
    800: '#061015',
    900: '#03080A',
  },
  neutral: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  accent: {
    coral: '#FF6B6B',
    orange: '#FF825C',
  },
  background: {
    light: '#FFFFFF',
    dark: '#1F2223',
  },
  text: {
    primary: '#1F2223',
    secondary: '#6C757D',
    light: '#FFFFFF',
  }
};

// Typography
export const typography = {
  fontFamily: {
    sans: "'Motiva Sans Light', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
    heading: "'Motiva Sans Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
  },
  lineHeight: {
    none: 1,
    tight: 1.25,
    snug: 1.375,
    normal: 1.5,
    relaxed: 1.625,
    loose: 2,
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

// Spacing
export const spacing = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  8: '2rem',
  10: '2.5rem',
  12: '3rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  32: '8rem',
};

// Border Radius
export const borderRadius = {
  none: '0',
  sm: '0.125rem',
  DEFAULT: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '1rem',
  '2xl': '1.5rem',
  '3xl': '2rem',
  full: '9999px',
};

// Shadows
export const shadows = {
  sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
};

// Breakpoints
export const breakpoints = {
  xs: '320px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

// Transitions
export const transitions = {
  default: 'all 0.3s ease-in-out',
  fast: 'all 0.15s ease-in-out',
  slow: 'all 0.5s ease-in-out',
};

// Global Styles
export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Motiva Sans Light";
    src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Motiva Sans Bold";
    src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${typography.fontFamily.sans};
    line-height: ${typography.lineHeight.normal};
    color: ${colors.text.primary};
    background-color: ${colors.background.light};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${typography.fontFamily.heading};
    color: ${colors.primary[400]};
    line-height: ${typography.lineHeight.tight};
    margin-bottom: ${spacing[4]};
  }

  h1 {
    font-size: ${typography.fontSize['5xl']};
    font-weight: ${typography.fontWeight.bold};
  }

  h2 {
    font-size: ${typography.fontSize['4xl']};
    font-weight: ${typography.fontWeight.semibold};
  }

  h3 {
    font-size: ${typography.fontSize['3xl']};
    font-weight: ${typography.fontWeight.semibold};
  }

  p {
    color: ${colors.text.secondary};
    font-size: ${typography.fontSize.base};
    line-height: ${typography.lineHeight.relaxed};
    margin-bottom: ${spacing[4]};
  }

  a {
    color: ${colors.primary[400]};
    text-decoration: none;
    transition: ${transitions.default};

    &:hover {
      color: ${colors.accent.coral};
    }
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  input, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`; 
import styled from "styled-components";
import { colors, typography, spacing, shadows, borderRadius } from "../../styles/theme";

interface StyledButtonProps {
  color?: string;
  borderRadius?: string;
  padding?: string;
  horizontalPadding?: string;
}

export const StyledButton = styled("button")<StyledButtonProps>`
  background: ${(p) => p.color || colors.primary[400]};
  color: ${colors.text.light};
  font-size: ${typography.fontSize.base};
  font-weight: ${typography.fontWeight.semibold};
  width: 100%;
  border: none;
  border-radius: ${(p) => p.borderRadius || borderRadius.lg};
  padding: ${(p) => p.padding || `${spacing[3]}`} ${(p) => p.horizontalPadding || `${spacing[6]}`};
  cursor: pointer;
  margin-top: ${spacing[2]};
  max-width: 180px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: ${shadows.md};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${colors.text.light};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .icon {
    margin-left: ${spacing[2]};
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover,
  &:active,
  &:focus {
    color: ${colors.text.light};
    background-color: ${colors.primary[500]};
    transform: translateY(-2px);
    box-shadow: ${shadows.lg};

    &::before {
      transform: translateX(100%);
    }

    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }

    .icon {
      transform: translateX(4px) scale(1.1);
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${shadows.md};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary[100]};
  }
`;
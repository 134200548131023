import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {
  Section,
  Title,
  Subtitle,
  ButtonGroup,
  DownloadButton,
  QRCodeSection,
  QRCodeContainer,
  QRCodeImage,
  QRCodeText,
  QRCodeTitle,
} from "./styles"; // Customize styles here

const LandingPage = ({ t }: { t: TFunction }) => {
  return (
    <>
      <Section className="hero-section">
        <Container>
          <Row justify="center" align="middle">
            <Col lg={12} md={12} sm={24} xs={24}>
              <Title>{t("Ready2Play Tennis")}</Title>
              <Subtitle>{t("Find partners and courts to elevate your game!")}</Subtitle>
              <ButtonGroup>
                <DownloadButton href="https://play.google.com/store/apps/details?id=com.rgs.ready2play">{t("Google Play")}</DownloadButton>
                <DownloadButton href="https://apps.apple.com/app/apple-store/id6683305729">{t("App Store")}</DownloadButton>
              </ButtonGroup>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <SvgIcon src="login.png" width="50%" height="auto" />
            </Col>
          </Row>
        </Container>
      </Section>

      {/* <Section className="features-section">
        <Container>
          <Title>{t("Features")}</Title>
          <Row gutter={[16, 16]}>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="connect.svg" />
                <FeatureText>{t("Find and connect with nearby tennis players.")}</FeatureText>
              </Feature>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="book.svg" />
                <FeatureText>{t("Book courts with ease from your phone.")}</FeatureText>
              </Feature>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="stats.svg" />
                <FeatureText>{t("Track your game stats to improve over time.")}</FeatureText>
              </Feature>
            </Col>
          </Row>
        </Container>
      </Section> */}

      {/* <Section className="cta-section">
        <Container>
          <Title>{t("Ready to Play?")}</Title>
          <Subtitle>{t("Join thousands of tennis enthusiasts worldwide.")}</Subtitle>
          <ButtonGroup>
            <DownloadButton href="/play-store-link">{t("Google Play")}</DownloadButton>
            <DownloadButton href="/app-store-link">{t("App Store")}</DownloadButton>
          </ButtonGroup>
        </Container>
      </Section> */}
    <Section className="footer-section">
      <Container>
        <Row gutter={[16, 16]} justify="space-between" align="top">
          <Col lg={8} md={12} sm={24} xs={24}>
            <SvgIcon src="horizontal-logo.svg" width="150px" height="auto" />
            <p style={{ marginTop: '8px', color: '#aaa' }}>
              &copy; {new Date().getFullYear()} Ready2Play. All rights reserved.
            </p>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <h4 style={{ color: '#fff' }}>Company</h4>
            <ul style={{ listStyle: 'none', padding: 0, color: '#aaa' }}>
              <li><a href="/about" style={{ color: '#aaa' }}>About Us</a></li>
              <li><a href="/contact" style={{ color: '#aaa' }}>Contact</a></li>
              <li><a href="/privacy" style={{ color: '#aaa' }}>Privacy Policy</a></li>
            </ul>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <h4 style={{ color: '#fff' }}>Get the App</h4>
            <ButtonGroup>
              <DownloadButton href="https://play.google.com/store/apps/details?id=com.rgs.ready2play">Google Play</DownloadButton>
              <DownloadButton href="https://apps.apple.com/app/apple-store/id6683305729">App Store</DownloadButton>
            </ButtonGroup>
          </Col>
        </Row>
        <QRCodeSection>
          <Row justify="center">
            <Col lg={10} md={14} sm={30} xs={30}>
              <QRCodeContainer>
                <QRCodeTitle>Download Ready2Play - Game On!</QRCodeTitle>
                <QRCodeImage src="/img/download.png" alt="Download Ready2Play QR Code" />
                <QRCodeText>Scan with your mobile phone to download from your device's app store</QRCodeText>
              </QRCodeContainer>
            </Col>
          </Row>
        </QRCodeSection>

        <section style={{ marginTop: '2rem' }}>
          <h2 style={{ fontSize: '1.5em', marginTop: '1em', color: '#333' }}>
            Privacy Policy &amp; Terms
          </h2>
          <p style={{ lineHeight: 1.6, color: '#000' }}>
            Please review our{' '}
            <a href="https://www.ready2play.us/privacy-policy" style={{ color: '#297EA3' }}>
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://www.ready2play.us/terms-and-conditions" style={{ color: '#297EA3' }}>
              Terms &amp; Conditions
            </a>{' '}
            to understand how we handle your data and your rights as a user.
          </p>
        </section>
      </Container>
    </Section>
    </>
  );
};

export default withTranslation()(LandingPage);
